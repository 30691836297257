<template>
  <div class="logo-container non-selectable">
    <div class="logo" :style="position">
      <q-img src="../assets/me.png"></q-img>
      <div class="text-center">
        <div class="home-title">KODE RED</div>
        <div class="home-subtitle">Full Stack Developer</div>

        <br /><br />
        <router-link to="/about" class="more">
          <q-btn
            outline
            class="grey clear"
            icon-right="fas fa-chevron-right"
            style="font-weight: 400"
            label="More about me"
            @click="changeTab(1)"
          >
          </q-btn>
        </router-link>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {};
  },
  computed: {
    position() {
      let transform = `rotateX(${this.rotateX}deg) rotateY(${this.rotateY}deg)`;
      return {
        top: this.moveY + "px",
        left: this.moveX + "px",
        "-webkit-transform": transform,
        "-ms-transform": transform,
        transform,
      };
    },
  },
  methods: {
    changeTab(tabIndex) {
      this.$emit("change-tab", tabIndex);
    },
  },
};
</script>
  
  <style scoped>
.logo-container {
  width: 250px;
  height: 250px;
  perspective: 800px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.logo {
  position: absolute;
  transform-style: preserve-3d;
}

.home-title {
  border: 1px solid #bbb;
  margin-top: 30px;
  font-size: 36px;
  text-shadow: 0px 5px 10px #050505;
  -webkit-text-shadow: 0px 5px 10px #050505;
  -moz-text-shadow: 0px 5px 10px #050505;
  padding: 7px;
}
.home-subtitle {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 300;
  text-shadow: 0px 5px 10px #050505;
  -webkit-text-shadow: 0px 5px 10px #050505;
  -moz-text-shadow: 0px 5px 10px #050505;
}
.home-img {
  border-radius: 50%;
  box-shadow: 0px 5px 10px #050505;
  -webkit-box-shadow: 0px 5px 10px #050505;
  -moz-box-shadow: 0px 5px 10px #050505;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.more {
  font-weight: 300;
}
</style>