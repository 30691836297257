<template>
  <div class="logo-container non-selectable">
    <div class="logo" :style="position">
      <center>
        <q-img
          style="height: 100%; max-width: 300px"
          src="../assets/logo.png"
        />
      </center>
      <div class="home-title">Contact Me</div>

      <q-form
        dark
        @submit="onSubmit"
        @reset="onReset"
        class="q-gutter-md q-pa-xl"
      >
        <q-input
          dark
          filled
          color="white"
          v-model="name"
          label="Your name *"
          hint="Name and surname"
          lazy-rules
          :rules="[(val) => (val && val.length > 0) || 'Please type something']"
        />

        <q-input
          filled
          dark
          color="white"
          v-model="subject"
          label="Subject *"
          hint="Briefly describe the purpose"
          lazy-rules
          :rules="[(val) => (val && val.length > 0) || 'Please type something']"
        />

        <q-input
          dark
          filled
          color="white"
          v-model="message"
          label="Message *"
          type="textarea"
          hint="Your message here"
          lazy-rules
          :rules="[(val) => (val && val.length > 0) || 'Please type something']"
        />

        <q-toggle
          v-model="accept"
          label="I accept the license and terms"
          color="white"
        />

        <div>
          <q-btn outline label="Submit" type="submit" color="white" />
          <q-btn
            outline
            label="Reset"
            type="reset"
            color="red"
            class="q-ml-sm"
          />
        </div>
      </q-form>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { Notify } from "quasar";
export default {
  setup() {
    const name = ref(null);
    const subject = ref(null);
    const message = ref(null);
    const accept = ref(false);

    const onSubmit = () => {
      if (!accept.value) {
        Notify.create({
          message: "Please check the form again!",
          caption: "Error",
          color: "negative",
        });
      } else {
        Notify.create({
          message:
            "Thank you for your message! We will be in contact within 5-7 business days!",
          caption: "Success",
          color: "positive",
        });
      }
    };

    const onReset = () => {
      name.value = null;
      subject.value = null;
      message.value = null;
      accept.value = false;
    };

    return {
      name,
      subject,
      message,
      accept,
      onSubmit,
      onReset,
    };
  },
};
</script>

<style scoped>
.logo-container {
  width: 70%;
  height: 50%;
  perspective: 800px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.logo {
  transform-style: preserve-3d;
}

.home-title {
  text-align: center;
  border: 1px solid #bbb;
  font-size: 36px;
  text-shadow: 0px 5px 10px #050505;
  -webkit-text-shadow: 0px 5px 10px #050505;
  -moz-text-shadow: 0px 5px 10px #050505;
  padding: 7px;
  color: white;
}

input,
textarea {
  color: white;
  font-weight: 300;
  border: 1px solid #999;
  padding-left: 10px;
}

input:hover,
textarea:hover,
input:active,
textarea:active,
input:focus,
textarea:focus {
  border: 1px solid #eee;
}

.send-btn {
  margin-top: 25px;
}

.input-alert {
  text-shadow: none;
  border: 2px solid #822;
  padding: 18px;
  color: #bbb;
  font-size: 15px;
  margin-top: 20px;
}

.close-alert {
  margin-left: 15px;
  color: #bbb;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.close-alert:hover {
  color: #822;
}

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.15s;
}

.fade-enter-active {
  transition-delay: 0.15s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
