<template>
  <div class="logo-container non-selectable">
    <div
      class="logo"
      :style="position"
    >
      <center>
        <q-img
          style="height: 100%; max-width: 300px"
          src="../assets/logo.png"
        />
      </center>
      <div class="home-title">About Me</div>
      <div class="home-subtitle">
        <p>{{ about }}</p>
        {{ getInTouch }}
        <q-btn
          outline
          icon="fas fa-mail-bulk"
          label="Contact me"
          @click="changeTab(3)"
        >
        </q-btn>
        <div class="tech-section">
          <div>Some technologies I use:</div>
          <div
            v-for="tech in techIUse"
            :key="tech"
            class="tech-item"
          >
            <q-chip
              class="tech-item-chip text-white"
              outline
              square
            >{{
              tech
            }}</q-chip>
          </div>
        </div>
        <!-- <div class="lang-section">
          <div>Languages I speak:</div>
          <div v-for="lang in langsISpeak" :key="lang" class="tech-item">
            {{ lang }}
          </div>
        </div> -->

        <!-- <div class="about-footer">
          <q-btn
            outline
            @click="$refs.cvModal.toggle()"
            label=" Download my Resume"
            icon-right="fas fa-file-download"
          >
          </q-btn>
        </div> -->
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      about:
        "I am a Software and Web Developer with a strong passion for coding and design. " +
        "I have gained valuable experience as a professional Software Developer, specializing in desktop and web application development, for over 8 years now. " +
        "Alongside my expertise in software development, I have a keen interest in video game development and modding using Unreal Engine. " +
        "Additionally, I enjoy modding in FiveM and other video game projects. " +
        "I am currently focusing on Front-end web development and continuously expanding my knowledge by exploring the latest frameworks such as Vue and React. " +
        "When I am not engrossed in coding, I indulge in design work and also engage in sports activities. Currently focused on building my own company ( Snowpeak Studio ) that will focus on GTA 5 Modding and FiveM Development through scripting, modeling, texture work and more.".replace(
          /'/g,
          "'"
        ),
      getInTouch: `If you want to get in touch with me, feel free to send me an email! `,
      techIUse: [
        "C#",
        "MS SQL Server",
        "HTML",
        "CSS",
        "JavaScript",
        "jQuery",
        "Vue.js",
        "GIT",
        "TFS",
        "Unity",
        "Unreal Engine",
        "Blender",
        "Photoshop",
        "Illustrator",
        "InDesign",
        "Premiere Pro",
        "After Effects",
        "Audition",
        "Lightroom",
        "FiveM",
      ],
    };
  },
  methods: {
    changeTab(tabIndex) {
      this.$emit("change-tab", tabIndex);
    },
  },
};
</script>
  
  <style scoped>
.logo-container {
  width: 70%;
  height: 50%;
  perspective: 800px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.logo {
  position: absolute;
  transform-style: preserve-3d;
}

.home-title {
  text-align: center;
  border: 1px solid #bbb;
  font-size: 36px;
  text-shadow: 0px 5px 10px #050505;
  -webkit-text-shadow: 0px 5px 10px #050505;
  -moz-text-shadow: 0px 5px 10px #050505;
  padding: 7px;
}
.home-subtitle {
  color: #ddd;
  margin-top: 15px;
  margin-bottom: 25px;
  font-size: 17px;
  font-weight: 300;
  text-shadow: 0px 5px 10px #050505;
  -webkit-text-shadow: 0px 5px 10px #050505;
  -moz-text-shadow: 0px 5px 10px #050505;
}

.tech-section {
  margin-top: 50px;
}
.lang-section {
  margin-top: 20px;
}
.tech-item {
  display: inline-block;
}
.tech-item-chip {
  border-color: white;
}

.about-footer {
  margin-top: 80px;
  text-align: center;
}
.cv-btn:active,
.cv-btn:focus {
  color: #14151a !important;
}

.close-modal-cv i {
  font-size: 30px;
}
.close-modal-cv {
  color: #777;
  position: absolute;
  top: 12px;
  right: 0;
}
.close-modal-cv:hover {
  color: #eee !important;
}

.modal-title-cv {
  font-weight: 300;
  margin-top: 40px;
  margin-bottom: 20px;
}
.info-divider {
  width: 200px;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(20, 20, 25, 0),
    rgba(50, 50, 55, 0.75),
    rgba(20, 20, 25, 0)
  );
  margin-bottom: 30px;
}
</style>