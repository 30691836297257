<template>
  <!-- Don't drop "q-app" class -->
  <div id="q-app">
    <MainLayout></MainLayout>
  </div>
</template>

<script>
import MainLayout from "./components/MainLayout";
export default {
  components: {
    MainLayout,
  },
};
</script>

<style>
html {
  overflow-x: hidden;
}
body {
  background: #14151a;
  color: #bbb;
}

.layout-padding,
.logo {
  margin-bottom: 40px;
}

button {
  border-radius: 0;
}
button:hover {
  color: #14151a !important;
}

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}
.fade-enter-active {
  transition-delay: 0.25s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}

@media screen and (max-height: 600px) {
  .layout-padding,
  .logo {
    margin-top: 60px;
  }
}
@media screen and (max-width: 400px) {
  .layout-padding,
  .logo {
    margin-top: 20px;
  }
}
</style>