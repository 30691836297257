<template>
  <q-layout class="bg-grey-10">
    <q-page-container>
      <div class="sticky-tabs">
        <q-tabs
          v-model="currentTabIndex"
          align="justify"
        >
          <q-tab
            v-for="(item, index) in menu"
            :key="item.id"
            @click="loadPage(item)"
            :name="index"
            :label="item.text"
            class="bg-grey-9 text-white"
            :icon="item.icon"
          >
          </q-tab>
        </q-tabs>
      </div>
      <q-page v-if="currentPage">
        <vue-particles
          class="particles"
          color="#dedede"
          :particleOpacity="0.7"
          :particlesNumber="80"
          shapeType="circle"
          :particleSize="4"
          linesColor="#dedede"
          :linesWidth="2"
          :lineLinked="true"
          :lineOpacity="0.4"
          :linesDistance="150"
          :moveSpeed="2"
          :hoverEffect="true"
          hoverMode="grab"
          :clickEffect="true"
          clickMode="push"
        >
        </vue-particles>
        <transition name="fade">
          <component
            :is="currentPage"
            @change-tab="changeTab"
          ></component>
        </transition>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
import HomePage from "./HomePage.vue";
import AboutPage from "./AboutPage.vue";
import PortfolioPage from "./PortfolioPage.vue";
import ContactPage from "./ContactPage.vue";

export default {
  data() {
    return {
      menu: [
        { id: 1, text: "Home", component: HomePage, icon: "fas fa-home" },
        { id: 2, text: "About", component: AboutPage, icon: "fas fa-user" },
        {
          id: 3,
          text: "Portfolio",
          component: PortfolioPage,
          icon: "fas fa-briefcase",
        },
        {
          id: 4,
          text: "Contact",
          component: ContactPage,
          icon: "fas fa-envelope",
        },
        {
          id: 5,
          text: "Store",
          link: "https://shop.snowpeakstudio.com",
          icon: "fas fa-shopping-cart",
        },
        {
          id: 6,
          text: "Discord",
          link: "https://discord.gg/6AMJbZ5PBZ",
          icon: "fab fa-discord",
        },
        {
          id: 7,
          text: "Github",
          link: "https://github.com/Kode-Red",
          icon: "fab fa-github-square",
        },
        {
          id: 8,
          text: "Snowpeak Studio",
          link: "https://snowpeakstudio.com",
          icon: "fas fa-snowflake",
        },
      ],
      currentTabIndex: 0,
      currentPage: "Home",
    };
  },
  mounted() {
    this.loadPage(this.menu[0]);
    this.currentTabIndex = 0;
    document.title = `Kode Red - ${this.menu[this.currentTabIndex].text}`;
  },
  methods: {
    loadPage(item) {
      if (item.link) {
        window.open(item.link, "_blank");
        this.loadPage(this.menu[0]);
        this.currentTabIndex = 0;
      } else {
        this.currentPage = item.component;
        document.title = `Kode Red - ${item.text}`;
      }
    },
    changeTab(tabIndex) {
      this.currentTabIndex = tabIndex;
      this.loadPage(this.menu[tabIndex]);
    },
  },
};
</script>

<style scoped>
.sticky-tabs {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
.particles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* Styles for the parent component */
</style>
