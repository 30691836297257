<template>
  <q-scroll-area
    style="height: 100vmin "
    light
    class="text-white rounded-borders"
  >
    <div class="logo-container non-selectable">
      <div
        class="logo"
        :style="position"
      >
        <center>
          <q-img
            style="height: 100%; max-width: 300px"
            src="../assets/logo.png"
          />
        </center>
        <div class="home-title q-mb-md">Portfolio</div>

        <div class=" row ">
          <q-card
            v-for="project in portfolio"
            :key="project.title"
            class="col-3 bg-grey-6 justify-center content-center text-center q-ma-xl"
          >
            <q-card-section class="text-h6 text-black text-weight-bold bg-grey-6">
              {{ project.title }}
            </q-card-section>

            <q-card-section
              align="center"
              horizontal
              class="card-section-horizontal"
            >
              <div class="img-hover q-pa-sm">
                <center>
                  <q-img
                    align="center"
                    v-if="project.thumbnail"
                    :src="project.thumbnail"
                    class="img-overlay"
                    @click="openImagePreview(project.thumbnail)"
                  >
                    <div class="icon-overlay">
                      <q-icon
                        class="icon-hover"
                        name="visibility"
                      />
                    </div>
                  </q-img>
                </center>
              </div>
            </q-card-section>
            <q-card-section>
              <div class="tech-section">
                <div
                  v-for="tech in project.tech"
                  :key="tech"
                  class="tech-item"
                >
                  <q-chip
                    outline
                    square
                  >{{ tech }}</q-chip>
                </div>
              </div>
            </q-card-section>
            <q-card-actions
              vertical
              v-if="project.thumbnail"
            >
              <q-btn
                color="black"
                flat
                dense
                :icon="
                  project.expanded ? 'fas fa-chevron-up' : 'fas fa-chevron-down'
                "
                label="Read more"
                @click="project.expanded = !project.expanded"
              />
            </q-card-actions>
            <q-slide-transition>
              <div v-show="project.expanded">
                <q-separator />
                <q-card-section class="text-black bg-grey-6">
                  {{ project.text }}
                </q-card-section>
                <q-separator />
                <q-btn
                  v-if="project.github"
                  dark
                  outline
                  square
                  color="black"
                  icon="fab fa-github-square"
                  class="q-ma-sm"
                />
                <q-btn
                  v-if="project.discord"
                  outline
                  square
                  color="blue-10"
                  icon="discord"
                  class="q-ma-sm"
                />
              </div>
            </q-slide-transition>
            <q-card-section
              v-if="!project.thumbnail"
              class="text-black bg-grey-6"
            >
              {{ project.text }}
              <q-separator />
              <q-btn
                v-if="project.github"
                dark
                outline
                square
                color="black"
                icon="fab fa-github-square"
                class="q-ma-sm"
              />
              <q-btn
                v-if="project.discord"
                outline
                square
                color="blue-10"
                icon="discord"
                class="q-ma-sm"
              />
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <transition
      name="bounce"
      mode="out-in"
    >
      <div
        v-if="showImagePreview"
        class="image-preview-dialog"
        @click="closeImagePreview"
      >
        <div class="image-container">
          <q-img
            class="image-preview"
            :src="imagePreviewSrc"
            mode="contain"
            @click="closeImagePreview"
          >
            <!-- Use q-img-placeholder for preview -->
          </q-img>
        </div>
      </div>
    </transition>
  </q-scroll-area>
</template>
  <script>
export default {
  data() {
    return {
      hoveredProject: null,
      showImagePreview: false,
      imagePreviewSrc: "",
      portfolio: [],
    };
  },
  mounted() {
    this.loadPortfolioData();
  },
  methods: {
    async loadPortfolioData() {
      try {
        const portfolioData = await import("../data/portfolioData.js");
        this.portfolio = portfolioData.default;
      } catch (error) {
        console.error("Failed to load portfolio data:", error);
      }
    },
    setHoveredProject(project) {
      this.hoveredProject = project;
    },
    clearHoveredProject() {
      this.hoveredProject = null;
    },
    openImagePreview(imageSrc) {
      this.imagePreviewSrc = imageSrc;
      this.showImagePreview = true;
    },
    closeImagePreview() {
      this.showImagePreview = false;
    },

    openUrl(url) {
      window.open(url, "_blank").focus();
    },
  },
};
</script>
  
<style scoped>
.image-preview-dialog {
  position: fixed;
  z-index: 999;
  padding: 10%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.icon-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.3s;
}

.image-container:hover .icon-overlay {
  opacity: 1;
  cursor: pointer;
}

.icon-hover {
  font-size: 36px;
  color: white;
  transition: transform 0.3s;
}

.image-container:hover .icon-hover {
  transform: scale(1.2);
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
.logo-container {
  width: 70%;
  height: 50%;
  perspective: 800px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.logo {
  transform-style: preserve-3d;
}

.home-title {
  text-align: center;
  border: 1px solid #bbb;
  font-size: 36px;
  padding: 7px;
}

.home-subtitle {
  color: #ddd;
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 17px;
  font-weight: 300;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -10px; /* Adjust negative margin for better spacing */
}

.card-section-horizontal {
  flex: 1;
}

.project-card {
  border: 1px solid #bbb;
}

.project-card button i {
  font-size: 28px;
}

.tech-item {
  color: #999;
  font-size: 16px;

  display: inline-block;
}

.project-card .card-content {
  height: 110px;
}

.card-actions > *:not(:last-child) {
  padding: 0;
}

.img-overlay {
  transition: 0.5s ease;
  height: 350px;
}
.img-overlay:hover {
  cursor: pointer;
}
.img-hover {
  position: relative;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
}

.hover-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 36px;
  color: white;
  opacity: 0;
  transition: opacity 0.3s;
}

.img-hover:hover .hover-icon {
  opacity: 1;
}
</style>
