
import './styles/quasar.scss'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/material-icons-round/material-icons-round.css'
import '@quasar/extras/material-icons-sharp/material-icons-sharp.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import Notify from 'quasar/src/plugins/Notify.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: [
    Notify
  ],
  build: {
    extendWebpack(cfg) {
      // Add a rule to handle JSON files
      cfg.module.rules.push({
        test: /\.json$/,
        loader: 'json-loader',
        type: 'javascript/auto',
      });
    },
  },

}